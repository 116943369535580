var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
      [
        _c(
          "c-table",
          {
            ref: "grid",
            attrs: {
              title: _vm.title,
              tableId: "grid",
              columnSetting: false,
              isFullScreen: false,
              columns: _vm.grid.columns,
              filtering: false,
              data: _vm.grid.data,
              selection: "multiple",
              rowKey: "envAirMstOutletPartId",
              isExcelDown: false,
              editable: _vm.editable,
            },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props }) {
                  return [
                    [
                      props.row.editFlag !== "C"
                        ? _c(
                            "q-chip",
                            {
                              staticStyle: { "margin-top": "3px !important" },
                              attrs: {
                                outline: "",
                                square: "",
                                color: "blue",
                                clickable: true,
                                "text-color": "white",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return (() => {
                                    _vm.rowClick(props.row)
                                  }).apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v(" ▶ ")]
                          )
                        : _vm._e(),
                    ],
                  ]
                },
              },
            ]),
          },
          [
            _c(
              "template",
              { slot: "table-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _vm.editable
                      ? _c("c-btn", {
                          attrs: { label: "LBLADD", icon: "add" },
                          on: { btnClicked: _vm.addrow1 },
                        })
                      : _vm._e(),
                    _vm.editable
                      ? _c("c-btn", {
                          attrs: { label: "LBLREMOVE", icon: "remove" },
                          on: { btnClicked: _vm.removeRow1 },
                        })
                      : _vm._e(),
                    _vm.editable
                      ? _c("c-btn", {
                          attrs: { label: "LBLSAVE", icon: "save" },
                          on: { btnClicked: _vm.saveRow1 },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
      [
        _c(
          "c-table",
          {
            ref: "grid2",
            attrs: {
              title: _vm.title2,
              tableId: "grid2",
              columnSetting: false,
              isFullScreen: false,
              columns: _vm.grid2.columns,
              filtering: false,
              data: _vm.grid2.data,
              selection: "multiple",
              rowKey: "envAirMstOutletPartHistoryId",
              isExcelDown: false,
              editable: _vm.editable,
            },
          },
          [
            _c(
              "template",
              { slot: "table-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _vm.editable && _vm.selectedenvAirMstOutletPartId
                      ? _c("c-btn", {
                          attrs: { label: "LBLADD", icon: "add" },
                          on: { btnClicked: _vm.addrow2 },
                        })
                      : _vm._e(),
                    _vm.editable && _vm.selectedenvAirMstOutletPartId
                      ? _c("c-btn", {
                          attrs: { label: "LBLREMOVE", icon: "remove" },
                          on: { btnClicked: _vm.removeRow2 },
                        })
                      : _vm._e(),
                    _vm.editable && _vm.selectedenvAirMstOutletPartId
                      ? _c("c-btn", {
                          attrs: { label: "LBLSAVE", icon: "save" },
                          on: { btnClicked: _vm.saveRow2 },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }